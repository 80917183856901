// FILE UPLOAD
// Courtesy of: https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/

.file-upload {
	text-align: center;
}

.file-upload__input {
	// Visually hide the "real" input
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;

	// a11y: focus
	&:focus + label {
		outline: 1px dotted #000;
		outline: -webkit-focus-ring-color auto 5px;
	}
}

.file-upload__label {
	display: block;
	max-width: 65rem;
	margin: $gutter auto;
	padding: $gutter-double;
	padding-top: $gutter * 1.5;
	border: 0.2rem dashed;
	border-color: $color-1;
	color: $color-lisb-lightest;
	transform: skewX(-$angle-lisb);
	cursor: pointer;
	font-style: italic;

	.color-primary,
	#fileupload-filename {
		color: $color-1;
	}
}
