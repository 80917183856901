// CONTENT

.content {
  flex-grow: 1;

  margin: 0 auto;
  max-width: 120rem;

  // Builder pages are full width
  [data-page="itemset-filters"] &,
  [data-page="itemset-builder"] & {
    max-width: none;
	margin: 0;
  }


  display: flex;
  flex-wrap: wrap;

  > * {
    flex-basis: 100%;
  }
  
  .no-result {
	text-align: center;
	padding: 0 $gutter;
	font-size: $fontsize-bigger;
	color: $color-1;
	@include text-shadow(2);
	font-weight: bold;
	margin: 0;
  }
}

.content-main {}

.content-aside {
  background-color: $color-2;
  text-align: center;
}

// AUTO SCROLL

@include bp('md') {

	.autoscroll {
		padding: $gutter;
		padding-top: $gutter-quad;
		padding-bottom: $gutter;
		position: relative;
	}
}

////////////////////////////////////////////////////////////////////////////////
// SELECTION ZONE

.selection {
	display: flex;
	flex-wrap: wrap;

	@include bp($bp-desktop) {
		flex-wrap: nowrap;
	}

	> * {
    flex-basis: 100%;
		flex-grow: 1;
	}

	.selection-maps,
	.selection-champions {
		.tiles {
			.tile {
				.tile__content {
					display: none;
				}
			}
		}
	}
}

.selection-focus {
	display: none;
	background-color: $color-lisb-bg-dark;
	transform: skew(- $angle-lisb);
}

.selection-submit {
  flex-basis: 15%;
  padding-top: $gutter-quarter;
}

@include bp('md') {

	.selection-maps,
	.selection-champions {
		display: flex;

		.section-subtitle {
			flex-grow: 0;
			// margin-right: $gutter-half;
			margin-bottom: 0;
			padding-top: $gutter - $gutter-quarter;
			// flex-basis: 15%;
		}

		.tiles {
			flex-grow: 1;
			justify-content: flex-start;
			margin-bottom: 0;

			.tile {
				// margin-right: -$gutter;
				margin-bottom: $gutter-half;
			}
		}
	}

	.selection-focus {
		flex-basis: 25%;
		display: block;
		margin-right: $gutter;
	}

	.selection-maps {
		// flex-basis: 10%;
		flex-basis: 29rem;
		flex-grow: 0;
	}

	.selection-champions {
		// flex-basis: 50%;
		flex-basis: auto;
		flex-grow: 1;
	}

	.selection-submit {
		// flex-basis: 15%;
		flex-basis: 38rem;
		flex-grow: 0;

		&.selection-submit--prev {
			flex-basis: 32rem;
		}
	}
}

////////////////////////////////////////////////////////////////////////////////
// SIMPLE MAIN CONTENT

.col--main-content {
	padding: $gutter-double $gutter;
}

.content-card {
	margin-bottom: $gutter-half;
	padding: $gutter-half $gutter;
	background-color: $color-lisb-bg-darkest;
	color: $color-lisb-lighter;
    border: 1px solid $color-1;
	@include shadow(3);

	@include bp('md') {
		padding: $gutter;
	}

	> h2 {
		// margin-top: -$gutter;
		// margin-left: -$gutter;
		// margin-right: -$gutter;
		// padding: $gutter-half $gutter;

		// border-bottom: 0.1rem solid;
	}

	blockquote {
		border-color: $color-1;
	}

	&.content-card--primary {
		background-color: $color-1;
		color: $color-lisb-bg-darker;

		> h2 {
		  color: black;
		}

		h3 {
		  color: $color-2;
		}

		blockquote {
		  border-color: $color-2;
		}
	}

	&.content-card--secondary {
		background-color: $color-lisb-bg-darkest;

		> h2 {
		  font-weight: bold;
		}

		h3 {
		  color: $color-2;
		}

		a {
		  color: $color-1;
		}
	}

  &.content-card--itemset {
    display: block;
    text-decoration: none;
    $color-2: #1E445C;
    position: relative;

    > .card-metas {
      color: rgba(255,255,255,0.5);

      span {
        color: rgba(255,255,255,0.75);
      }

      @include bp('md') {
        padding-top: $gutter-quarter;
        padding-bottom: $gutter-quarter;
        position: absolute;
        top: 0;
        right: $gutter;
    	}
    }

    h4 {
      color: $color-1;
      font-size: $fontsize-big;
      font-weight: normal;

      @include bp('md') {
        line-height: $tilesize-item;
        flex-basis: 20%;
    	}
    }

    > .grid {

      > * {
        &:nth-child(1) { flex-basis: 6rem; flex-grow: 0; };
        &:nth-child(2) { flex-basis: 20rem; flex-grow: 0; };
        &:nth-child(3) { flex-basis: 12rem; flex-grow: 0; };
        &:nth-child(4) { flex-basis: auto; flex-grow: 1; };
      }
    }

    .tiles {
      justify-content: flex-start;
    }

    .tile {
      margin-bottom: $gutter-half;
    }

    .tile--champion {
      flex-basis: $tilesize-item;
      height: $tilesize-item;
    }

    .tile--map {
      flex-basis: $tilesize-item * 2;
      height: $tilesize-item;
    }

    .tile__name {
      display: none;
    }

    &.content-card--primary {
      background-color: #292312;

      > h3 {
        color: $color-1;
      }
    }

    &.content-card--secondary {
      background-color: $color-lisb-bg-darkest;

      > h3 {
        color: $color-1;
      }
    }

    @include bp('md') {
      padding-top: $gutter-quarter;
      padding-bottom: $gutter-quarter;
  	}
  }


}
