////////////////////////////////////////////////////////////////////////////////
// ITEMSET

.itemset {

}

.itemset-block {
  margin-bottom: $gutter;
  transform: skew(- $angle-lisb);
}


.itemset-block__name {
  margin-bottom: 0;
  display: flex;
  flex-wrap: nowrap;

  > .form-item {
    margin-bottom: 0;
    transform: skew(0);
    flex-basis: 50%;

    &.form-item--text {
      flex-grow: 1;
    }
    &.form-item--checkbox {
      flex-grow: 0;
      flex-basis: auto;
    }
  }

  .itemset-block--add & {
    display: none;
  }
}

.itemset-block__content {
  padding-top: $gutter-half;
  display: flex;
  flex-wrap: wrap;
  min-height: $tilesize-item + $gutter + 0.4rem;
  border: 0.2rem solid $color-1;

  > .tile--item {
    transform: skew(0);

	.tile__content {
		display: none;
	}
  }

  // Plus sign
  .itemset-block--add & {
    padding: $gutter-half;
    justify-content: center;
    height: 100px;
	line-height: 70px;
    border: 0.2rem dashed;
    background-color: transparent;
    cursor: pointer;
	border-color: $color-1;
	font-style: italic;

	span {
	  color: $color-1;
    }
  }
}
