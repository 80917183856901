////////////////////////////////////////////////////////////////////////////////
// BTN

.btn {
	display: inline-block;
	margin: 0 $gutter-quarter;
	padding: $gutter-half * 1.5 $gutter * 1.5;
	text-transform: uppercase;
	text-decoration: none;
	cursor: pointer;

	transform: skew(- $angle-lisb);
	font-size: $fontsize-intermediate;
	line-height: 1;
	font-weight: normal;
	@include shadow(2);
	transition: all 0.2s;
	color: $color-1;

	background-color: $color-lisb-bg-darkest;
	border: 1px solid $color-1;

	&:hover {
		background-color: $color-1;
		color: $color-lisb-bg-darkest;
		@include shadow(4);
	}

	&.btn--primary {
		color: $color-lisb-bg-dark;
		background-color: $color-1;
		border: 1px solid lighten($color-1, 20%);
		border-bottom-color: darken($color-1, 20%);
		border-right-color: darken($color-1, 20%);

		&:hover {
			background-color: $color-1-dark;
		}
	}

	&.btn--secondary {
		background-color: $color-2;
		border: 1px solid lighten($color-2, 20%);
		border-bottom-color: darken($color-2, 20%);
		border-right-color: darken($color-2, 20%);

		&:hover {
			background-color: $color-2-dark;
		}
	}

	.section--submit & {
		// margin-bottom: $gutter-half;
	}
}

.cta {
	margin-top: $gutter;
	text-align: center;
}
