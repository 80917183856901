////////////////////////////////////////////////////////////////////////////////
// COMMON

$bodybg-pattern-size: $gutter-double;
$bodybg-pattern-intensity: 0.1;

*, *:before, *:after {
	box-sizing: border-box;
}

html {
}

body {
	overflow-x: hidden;
	color: $color-lisb-lighter;

	background-color: $color-lisb-bg-darkest;
	// background-image:
	// 	repeating-linear-gradient(
	// 	90deg + $angle-lisb,
	// 	transparent,
	// 	transparent $bodybg-pattern-size,
	// 	rgba(0,0,0,$bodybg-pattern-intensity) $bodybg-pattern-size,
	// 	rgba(0,0,0,$bodybg-pattern-intensity) 2 * $bodybg-pattern-size
	// 	),
	// 	radial-gradient(ellipse farthest-corner, lighten($color-lisb-bg, 5%), darken($color-lisb-bg, 10%))
	// ;

	@include bp('md') {
		min-height: 100vh;

		// Builder pages hasn't scroll
		&[data-page="itemset-filters"],
		&[data-page="itemset-builder"] {
			height: 100vh;
			overflow: hidden;
		}
	}
}


////////////////////////////////////////////////////////////////////////////////
// HEADER & FOOTER

////////////////////////////////////////////////////////////////////////////////
// BASE SITE LAYOUT

// SITE WRAPPER

.site-wrapper {
	min-height: 100vh;
  display: flex;
  flex-direction: column;

	@include bp('md') {
		height: 100vh;
	}
}

// MAIN

.main {
	flex-grow: 1;

	margin-top: $gutter * 1.5;

	@include bp('md') {
		margin-top: $gutter * 2;
	}

	@include bp('md') {
		// Builder pages are flexy
		[data-page="itemset-filters"] &,
		[data-page="itemset-builder"] & {
			display: flex;
			flex-direction: column;
		}
	}
}

// HEADER

.header,
.footer {
	background-color: $color-lisb-bg-darkest;
	@include shadow(3);
}

.header {
	position: fixed;
	top: 0;
	width: 100%;
	// border-bottom: 0.4rem solid $color-2;
	z-index: 10000;
}

// FOOTER

.footer {
	padding: $gutter-half;
	text-align: center;
	color: $color-lisb-lightest;

	border-top: 0.2rem solid $color-1;

	@include bp('md') {
		padding: $gutter;

		[data-page="itemset-filters"] &,
		[data-page="itemset-builder"] & {
			padding: $gutter-half;

			// Builder pages have fixed footer
			position: absolute;
			bottom: 0;
			width: 100%;
		}
	}


}

// OVERLAY

.site-overlay {
	// display: none;
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,0.75);

	// Show mobile navigation
	transition: all 0.2s ease-out;
	transform: translateX(-100vw);

	&.visible {
		transform: translateX(0);
	}
}
