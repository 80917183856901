// FORM

.form-itemsets {
  text-align: center;

  > button {
    margin-top: $gutter-half;
  }
}

// PAGINATION

.listAlpha {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > li {
    list-style-type: none;

    &.disabled {
      padding: $gutter-quarter $gutter-half;
      margin: $gutter-quarter / 2;
    }

    > a {
      display: block;
      padding: $gutter-quarter $gutter-half;
      margin: $gutter-quarter / 2;
      background-color: $color-lisb-bg-darkest;
      text-decoration: none;
	  border: 0.1rem solid $color-1;

      &:hover {
        background-color: $color-lisb-bg-dark;       
      }
    }
	
	&.alphaOn > a {
      background-color: #292312;
    }
  }
}
