////////////////////////////////////////////////////////////////////////////////
// TILES

.tiles {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	// justify-content: space-between;
	justify-content: center;
    margin-bottom: $gutter;
}


.tile {
	position: relative;

	flex-basis: $tilesize-base;
	flex-shrink: 1;
	align-content: flex-start;
	height: $tilesize-base;
	margin: $gutter-half;
	margin-bottom: $gutter + $gutter-half;
	margin-top: 0;

	&.tile--map {
		flex-basis: $tilesize-base * 2;
	}
	&.tile--champion {}

	&.tile--item {
		flex-basis: $tilesize-item;
		height: $tilesize-item;

		.itemset-block__content & {
			margin-bottom: $gutter-half;
		}
	}

	padding: $gutter-quarter;
	transform: skew(- $angle-lisb);
	transition: all 0.4s;

	&:not(.tile--passive):hover {
		// padding: 0;
		cursor: pointer;
	}

	.content-aside & {
		flex-basis: $tilesize-mini;
		height: $tilesize-mini;
		margin-bottom: 0;

		&.tile--map {
			flex-basis: $tilesize-mini * 2;
		}
	}

	// CSS triangles

	&:after {
		content: "";
		display: block;
		position: absolute;
		z-index: +1;

		width: 0;
		height: 0;
		border-style: solid;
	}

	$item-corner-size: $gutter-double;


	&:after {
		bottom: 0;
		right: 0;
		border-width: 0 0 $item-corner-size $item-corner-size;
		border-color: transparent transparent transparent transparent;

	}

	&.tile--selected:after {
		border-color: transparent transparent $color-1 transparent;
	}

	&.tile--selected.tile--any:after {
		border-color: transparent;
	}
}

.tile__image {
	position: relative;
	height: 0;
	overflow: hidden;
	padding-bottom: 100%;
	box-shadow: 0 0 0 0.4rem $color-lisb-bg-darkest, 0 0 0 0.6rem $color-1;
	// @include shadow(2);

	.tile--map & {
		padding-bottom: 46.2%;

		.content-aside & {
			padding-bottom: 45.0%;
		}
	}

	.tile--selected & {
		border-color: $color-1;
	}

	> img {
		max-width: none; // reset default responsive img
		position: absolute;
		width: 115%;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0) skew($angle-lisb);
		transition: all 0.4s;

		.tile:not(.tile--passive):hover & {
			width: 135%;
		}
	}

}

.tile__content {
	// background-color: red;
}

.tile__name,
.tile__title {
	display: inline-block;
	position: absolute;
	left: -0.6rem;
	padding: $gutter-quarter;
	color: rgba(255,255,255,0.925);
	text-align: right;
	transition: all 0.2s;
	white-space: nowrap;
	font-weight: normal;
}

.tile__name {
	bottom: -($gutter + 0.6rem);

	.tile--selected & {
		color: $color-1;
	}
}

.tile__title {
	display: none; // omg
	bottom: $gutter;
	padding-top: $gutter-half;
	opacity: 0;
	font-size: 75%;

	.tile:not(.tile--passive):hover & {
		bottom: $gutter-quarter;
		opacity: 1;
		color: white;
		background-color: black;
	}
}

.tile__lore {
	display: none;
}

.tile__hidden-input {
	display: none;
}

// TOOLTIP (only content, for layout see ./tooltipster.scss)

.tooltip-content {
}

.tooltip-header {
	display: flex;
}

.tooltip-content__image {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: $tilesize-item * 0.75;

	> img {
		display: block;
		width: 100%;
	}
}

.tooltip-content__body {
	flex-grow: 1;
	margin-left: $gutter-half;
}

.item-desc {
}

.item-desc__title {
	margin-bottom: 0;
	font-size: $fontsize-big;
	font-weight: bold;
	color: white;
}

.item-desc__gold {
	font-size: $fontsize-base;
	font-weight: bold;
	color: $color-1;
	margin-bottom: $gutter-half;
}

.item-desc__description {
	.item-desc__stats,
	.item-desc__property {
		color: $color-1;
	}
}

// Icons

.gold-icon {
	position: relative;
	top: 0.125rem;
	display: inline-block;
	width: 1rem;
	height: 1rem;
	background-image: url("../img/gold.png");
}

.list-icon {
	position: relative;
	top: 1.25rem;
	display: inline-block;
	width: 4rem;
	height: 3.7rem;
	background-image: url("../img/list-icon.png");
}

.cloud-icon {
	position: relative;
	top: 1.25rem;
	display: inline-block;
	width: 3.7rem;
	height: 3.1rem;
	background-image: url("../img/clowd-icon.png");
}

.tiles--itemsets-maps {
	@include bp('md') {
		justify-content: flex-start;
	}
}

.form-itemsets {
	.grid--itemsets-maps {
		@include bp('md') {
			> article {
				flex-basis: 54%;
			}
			> article:first-child {
				flex-basis: 46%;
			}
		}
		@include bp('lg') {
			> article {
				flex-basis: 62%;
			}
			> article:first-child {
				flex-basis: 38%;
			}
		}
	}
}
