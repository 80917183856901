////////////////////////////////////////////////////////////////////////////////
// SECTION FORM

.filters {
  margin-bottom: $gutter-double;
}

.form-block {
	display: flex;
	flex-wrap: wrap;
	margin-left: - $gutter-quarter;
	margin-right: - $gutter-quarter;
	margin-bottom: $gutter-half;
}


// Margin bottom
.form-title,
.form-item,
.form-input {
	margin-bottom: $gutter-half;
}

// Horizontal padding
.form-title,
.form-input, // text
.form-label {
	padding-top: $gutter-half;
	padding-bottom: $gutter-half;
	font-size: $fontsize-base;
}

.form-item--radio,
.form-item--checkbox,
.form-input { // text
	text-align: center;
	border: 0.2rem solid $color-1;

	&.form-input,
	> .form-label {
		padding-left: $gutter;
		padding-right: $gutter;
		background-color: $color-lisb-bg-darker;
		@include shadow(2);
		transition: all 0.4s;
		cursor: pointer;
	}

	&:hover,
	& > .form-label:hover {
		background-color: black;
	}
}

.form-title,
.form-item {
  transform: skew(- $angle-lisb);
  margin-left: $gutter-quarter;
	margin-right: $gutter-quarter;
}

.form-title {
	padding-left: $gutter;
	padding-right: $gutter;
	text-align: center;
	text-transform: uppercase;
	background-color: $color-1;
	color: white;
	border: 0.2rem solid $color-1;
	background-image: linear-gradient(to bottom, #B88A05, #896E25);
	text-shadow: 0.2rem 0.2rem 0.1rem rgba(0,0,0,0.5);
	font-size: $fontsize-base;
}

.form-item {
	flex-grow: 1;
}

.form-item--text {
	> .form-input {
		width: 100%;
		text-align: left;
		background-color: white;
		border-color: white;
		color: #757575;
		height: 4.6rem; // hacky
		font-family: 'Roboto', sans-serif;
		cursor: text;
	}
	
	> .form-input::placeholder {
		color: #a6a6a6;
	}
}

.form-item--radio,
.form-item--checkbox {

	> .form-label {
		display: block;
	}

	> .form-input:checked + .form-label {
		background-color: $color-1;
	}
}

.form-input { // text
	margin-bottom: 0; // reset
	flex-basis: 100%;

	background-color: $color-lisb-bg;
	color: $color-lisb;
}

.form-item--checkbox 	{
	> .form-input[type="checkbox"] {
		display: none;
	}

	.form-item--text + & {
		margin-left: -$gutter-quarter;
		border-bottom: 0;
	}
}

.form-btn-delete {
	position: relative;
	height: 4.4rem;

	&:before, &:after {
		position: absolute;
		content: "";
		top: 50%;
		left: 50%;
		width: $gutter;
		height: 0.2rem;
		background-color: $color-1;
	}

	&:before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}

.form-item--radio 		{	> .form-input[type="radio"] { display: none; } }

.form-item--itemsets {
  margin-left: 0;
  margin-right: 0;
}
