////////////////////////////////////////////////////////////////////////////////
// SECTIONS

.section {
	padding: 0 $gutter;
	padding-bottom: $gutter;

	@include bp('md') {
		.content-main & {
			position: relative;
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.section--header {
	padding: 0;
	padding-top: 32vw;
	background-size: cover;
	background-position: center;

	&.section--header--home {
		background-position: 0% 0%;
		background-image: url("/dist/img/banner_home.jpg");
	}
	&.section--header--itemsets { background-image: url("/dist/img/banner_itemsets.jpg"); }
	&.section--header--import { background-image: url("/dist/img/banner_import.jpg"); }
	&.section--header--about { background-image: url("/dist/img/banner_about.jpg"); }
	&.section--header--donate { background-image: url("/dist/img/banner_donate.jpg"); }
	&.section--header--download { background-image: url("/dist/img/banner_download.jpg"); }
}

.section--submit {
	padding-top: $gutter;
	padding-bottom: $gutter;

	// background-color: $color-1;
	border-left: none;
	border-right: none;

	@include bp('md') {
		padding-bottom: $gutter-half;
	}
}

.section--items {
}

.section--maps,
.section--items-filters {
}

.section--itemset {
}

// SECTION TITLE

.section-title {
	margin-left: -$gutter;
	margin-right: -$gutter;
	margin-bottom: $gutter-double;
	color: $color-lisb-bg-darker;
	text-transform: uppercase;
	text-align: center;
	border-top: 0.2rem solid $color-1;

	@include bp('md') {
		.content-main & {
			position: absolute;
			margin-left: 0;
			margin-right: 0;
			margin-bottom: 0;
			z-index: 1000;
			width: 100%;
			top: 0;
			// text-align: left;
		}
	}

	> span {
		position: relative;
		width: 24rem;
		display: inline-block;
		padding: ($gutter-half - 0.4rem) $gutter;
		background-color: $color-1;
		background-image: linear-gradient(to bottom, #B88A05, #896E25);
		color: white;
		text-align: center;
		font-size: $fontsize-bigger;
		font-weight: lighter;
		transform: skew(- $angle-lisb);
		text-shadow: 0.2rem 0.2rem 0.1rem rgba(0,0,0,0.5);

		&:after {
			content: " »";
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			bottom: (-$gutter + 0.4rem);
			left: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: ($gutter - 0.4rem) 12rem 0 12rem;
			border-color: #896E25 transparent transparent transparent;		}
	}

  .content-aside & {
    border-color: $color-2;

    > span {
      background-color: $color-2;
      color: white;

      &:before {
        background-color: $color-2-darker;
      }
    }
  }
}

// SECTION SUBTITLE

.section-subtitle {
	display: inline-block;
	// display: none;
	margin-bottom: $gutter-half;
	padding: $gutter-half $gutter;
	// background-color: $color-lisb-bg-dark;
	color: $color-1;
	font-size: $fontsize-big;
	transform: skew(- $angle-lisb);
}

// SECTION HEADER

.hero-content {
	padding: $gutter-double $gutter;
	background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));

	.section--header.section--header--home & {
		background: transparent;
	}
}

.page-title,
.page-subtitle,
.page-cta {
	text-align: center;
	padding: 0 $gutter;
}

.page-title {
	font-size: $fontsize-biggest * 2;
	color: white;
	@include text-shadow(2);
	font-weight: bold;
	margin: 0;
}

.page-subtitle {
	font-size: $fontsize-big * 2;
	font-weight: lighter;
	text-align: center;
	color: $color-lisb-lighter;

	.page-subtitle-emphasis {
		color: $color-1;
		font-weight: bold;
	}
}

.page-cta {
	margin-top: $gutter-double;
}

.page-cta--home {
	margin-bottom: $gutter-double;
}
