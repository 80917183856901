// VARIABLES

// Base

$angle-lisb: 				0; // used to be 9deg

$gutter: 					2.4rem;

$fontsize-base: 			1.6rem;
$fontsize-intermediate:		1.8rem;
$fontsize-big: 				2.0rem;
$fontsize-bigger: 			2.4rem;
$fontsize-biggest: 			2.6rem;

// Tile sizes

$tilesize-base:           8.5rem;
$tilesize-mini:           $tilesize-base * 0.8;
$tilesize-item:           7rem;

// Breakpoints

$breakpoints: (
  'xs':                   20em,  //  320px
  'sm':                   30em,  //  480px
  'ms':                   45em,  //  720px
  'md':                   60em,  //  960px
  'lg':                   80em,  // 1280px
  'xl':                   100em, // 1600px
  'xxl':                  120em  // 1920px
);

$bp-desktop:              'lg';
$bp-navbar:               'ms';


// Colors

$color-1: 								#98782b;
$color-2: 								#002d3e;

$color-gold:               #d4af37;

$color-lisb-bg: 					#222;
$color-lisb-bg-dark: 			#111;
$color-lisb-bg-darker: 		#080808;
$color-lisb-bg-darkest: 	#000;
$color-lisb-bg-light: 		#282828;
$color-lisb-bg-lighter: 	#333;
$color-lisb-bg-lightest:	#444;

$color-lisb-dark:					#888;
$color-lisb: 							#aaa;
$color-lisb-light: 				#ccc;
$color-lisb-lighter:			#eee;
$color-lisb-lightest:			#fff;

$color-nav-bg:						$color-lisb-bg-dark;


// Computed

$color-1-lightest:				lighten($color-1, 50%);
$color-1-lighter: 				lighten($color-1, 25%);
$color-1-light: 					lighten($color-1, 10%);
$color-1-dark: 						darken($color-1, 10%);
$color-1-darker: 					darken($color-1, 25%);
$color-1-darkest:					darken($color-1, 35%);

$color-2-lightest:				lighten($color-2, 50%);
$color-2-lighter: 				lighten($color-2, 25%);
$color-2-light: 					lighten($color-2, 10%);
$color-2-dark: 						darken($color-2, 10%);
$color-2-darker: 					darken($color-2, 25%);
$color-2-darkest:					darken($color-2, 35%);

$gutter-half: $gutter / 2;
$gutter-quarter: $gutter / 4;
$gutter-double: $gutter * 2;
$gutter-triple: $gutter * 3;
$gutter-quad: $gutter * 4;



