////////////////////////////////////////////////////////////////////////////////
// NAVBAR

// NAVBAR

.navbar {
	display: flex;

	> * {
		> * {
			padding: 0 $gutter;
			line-height: $gutter * 1.5;

			@include bp($bp-navbar) {
				line-height: $gutter * 2;
			}
		}
	}
}

// BRAND

.navbar-brand {
	flex-grow: 1;

	> a {
		// position: absolute;
		top: $gutter-quarter;
		color: $color-1;
		text-decoration: none;

		font-size: 0;
		display: block;
		// top: -$gutter-half;
		// left: $gutter-half;
		width: 5.48rem;
		height: 2.0rem;
		margin-top: 0.8rem;
		margin-left: $gutter-half;

		@include bp($bp-navbar) {
			width: 8.22rem;
			height: 3rem;
			margin-top: 1.1rem;
			margin-left: $gutter;
		}
		background-size: contain;
		background-image: url("../img/logo-header.png");

		&:hover {
			color: white;
		}
	}
}

// TOGGLE

.navbar-toggle {

	position: relative;
	z-index: 20000;

	@include bp($bp-navbar) {
		display: none;
	}

	> button {
		position: relative;
		top: 0;
		color: inherit;
		border: none;
		background-color: transparent;
		font-weight: bold;
		transform: skewX(-$angle-lisb);
	}
}

// NAV

/*ul*/.navbar-nav {
	position: fixed;
	top: 0;
	right: 0;
	width: 75vw;
	height: 100vh;
	font-size: $fontsize-base;
	font-weight: bold;

	display: flex;
	flex-direction: column;
	justify-content: center;

	padding-top: $gutter;
	padding-bottom: $gutter;
	padding-left: 0;
	background-color: rgba(0,0,0,0.971);
	border-left: 0.2rem solid $color-1;

	// Show mobile navigation
	transition: all 0.2s ease-out;
	transform: translateX(75vw);

	.navbar.visible & {
		transform: translateX(0);
	}

	// Navigation items
	> li {
		flex-grow: 0;
		margin: 0;
		list-style-type: none;

		> a {
			display: block;
			text-align: center;
			text-decoration: none;
			color: $color-lisb;
			text-transform: uppercase;

			&:hover {
				color: $color-lisb-lightest;
			}

		}

		&.active {

			> a {
				color: $color-lisb-lightest;
			}
		}
	}

	// Desktop navbar-nav
	@include bp($bp-navbar) {
		display: block;
		position: static;
		width: auto;
		height: auto;

		padding: 0;

		background-color: transparent;
		border-left: none;
		transform: translateX(0);

		> li {
			display: inline-block;

			> a {

				&:hover {
				}

			}

			&.active {
				border-top: 0.4rem solid $color-1;

				> a {
				}
			}
		}
	}

}
