@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Averia+Serif+Libre:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

h2 {
  color: #98782b;
}

h3 {
  color: #888;
}

blockquote {
  padding-left: 1.2rem;
  border-left: 0.4rem solid #002d3e;
}

.text-center {
  text-align: center;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
}

.btn,
.hero-content,
h1, h2, h3, h4,
.form-title,
.section-title,
.section-subtitle,
.header,
.item-desc__title,
.item-desc__gold,
.no-result {
  font-family: 'Averia Serif Libre', cursive;
}

h1, h2, h3,
p {
  margin-bottom: 2.4rem;
}

h1 {
  font-size: 5.2rem;
  font-weight: bold;
}

h2 {
  font-size: 2.6rem;
  font-weight: bold;
  text-transform: uppercase;
}

h3 {
  font-size: 2.6rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

p {
  margin-bottom: 1.2rem;
}

ul {
  padding-left: 1.8rem;
}

li {
  list-style-type: circle;
  margin-bottom: 1.2rem;
}

blockquote {
  margin-bottom: 2.4rem;
  font-style: italic;
}

hr {
  margin-bottom: 1.2rem;
}

a {
  color: #98782b;
  font-weight: bold;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 60em) {
  .row {
    flex-wrap: nowrap;
  }
}

.row > * {
  flex-basis: 100%;
  flex-grow: 1;
}

@media (min-width: 60em) {
  .row > * {
    flex-basis: auto;
  }
}

@media (min-width: 60em) {
  .col--maps {
    flex-basis: 27%;
  }
  .col--champions {
    flex-basis: 73%;
  }
  .col--items-filter {
    flex-basis: 27%;
  }
  .col--items {
    flex-basis: 36.5%;
  }
  .col--itemset {
    flex-basis: 36.5%;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: -1.2rem;
}

@media (min-width: 60em) {
  .grid {
    flex-wrap: nowrap;
  }
}

.grid > * {
  flex-grow: 1;
  flex-basis: 100%;
  margin: 1.2rem;
}

@media (min-width: 60em) {
  .grid {
    flex-basis: auto;
  }
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  color: #eee;
  background-color: #000;
}

@media (min-width: 60em) {
  body {
    min-height: 100vh;
  }
  body[data-page="itemset-filters"], body[data-page="itemset-builder"] {
    height: 100vh;
    overflow: hidden;
  }
}

.site-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media (min-width: 60em) {
  .site-wrapper {
    height: 100vh;
  }
}

.main {
  flex-grow: 1;
  margin-top: 3.6rem;
}

@media (min-width: 60em) {
  .main {
    margin-top: 4.8rem;
  }
}

@media (min-width: 60em) {
  [data-page="itemset-filters"] .main,
  [data-page="itemset-builder"] .main {
    display: flex;
    flex-direction: column;
  }
}

.header,
.footer {
  background-color: #000;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.footer {
  padding: 1.2rem;
  text-align: center;
  color: #fff;
  border-top: 0.2rem solid #98782b;
}

@media (min-width: 60em) {
  .footer {
    padding: 2.4rem;
  }
  [data-page="itemset-filters"] .footer,
  [data-page="itemset-builder"] .footer {
    padding: 1.2rem;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.site-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  transition: all 0.2s ease-out;
  transform: translateX(-100vw);
}

.site-overlay.visible {
  transform: translateX(0);
}

.content {
  flex-grow: 1;
  margin: 0 auto;
  max-width: 120rem;
  display: flex;
  flex-wrap: wrap;
}

[data-page="itemset-filters"] .content,
[data-page="itemset-builder"] .content {
  max-width: none;
  margin: 0;
}

.content > * {
  flex-basis: 100%;
}

.content .no-result {
  text-align: center;
  padding: 0 2.4rem;
  font-size: 2.4rem;
  color: #98782b;
  text-shadow: 2 2 0 rgba(0, 0, 0, 0.75);
  font-weight: bold;
  margin: 0;
}

.content-aside {
  background-color: #002d3e;
  text-align: center;
}

@media (min-width: 60em) {
  .autoscroll {
    padding: 2.4rem;
    padding-top: 9.6rem;
    padding-bottom: 2.4rem;
    position: relative;
  }
}

.selection {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 80em) {
  .selection {
    flex-wrap: nowrap;
  }
}

.selection > * {
  flex-basis: 100%;
  flex-grow: 1;
}

.selection .selection-maps .tiles .tile .tile__content,
.selection .selection-champions .tiles .tile .tile__content {
  display: none;
}

.selection-focus {
  display: none;
  background-color: #111;
  transform: skew(0);
}

.selection-submit {
  flex-basis: 15%;
  padding-top: 0.6rem;
}

@media (min-width: 60em) {
  .selection-maps,
  .selection-champions {
    display: flex;
  }
  .selection-maps .section-subtitle,
  .selection-champions .section-subtitle {
    flex-grow: 0;
    margin-bottom: 0;
    padding-top: 1.8rem;
  }
  .selection-maps .tiles,
  .selection-champions .tiles {
    flex-grow: 1;
    justify-content: flex-start;
    margin-bottom: 0;
  }
  .selection-maps .tiles .tile,
  .selection-champions .tiles .tile {
    margin-bottom: 1.2rem;
  }
  .selection-focus {
    flex-basis: 25%;
    display: block;
    margin-right: 2.4rem;
  }
  .selection-maps {
    flex-basis: 29rem;
    flex-grow: 0;
  }
  .selection-champions {
    flex-basis: auto;
    flex-grow: 1;
  }
  .selection-submit {
    flex-basis: 38rem;
    flex-grow: 0;
  }
  .selection-submit.selection-submit--prev {
    flex-basis: 32rem;
  }
}

.col--main-content {
  padding: 4.8rem 2.4rem;
}

.content-card {
  margin-bottom: 1.2rem;
  padding: 1.2rem 2.4rem;
  background-color: #000;
  color: #eee;
  border: 1px solid #98782b;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
}

@media (min-width: 60em) {
  .content-card {
    padding: 2.4rem;
  }
}

.content-card blockquote {
  border-color: #98782b;
}

.content-card.content-card--primary {
  background-color: #98782b;
  color: #080808;
}

.content-card.content-card--primary > h2 {
  color: black;
}

.content-card.content-card--primary h3 {
  color: #002d3e;
}

.content-card.content-card--primary blockquote {
  border-color: #002d3e;
}

.content-card.content-card--secondary {
  background-color: #000;
}

.content-card.content-card--secondary > h2 {
  font-weight: bold;
}

.content-card.content-card--secondary h3 {
  color: #002d3e;
}

.content-card.content-card--secondary a {
  color: #98782b;
}

.content-card.content-card--itemset {
  display: block;
  text-decoration: none;
  position: relative;
}

.content-card.content-card--itemset > .card-metas {
  color: rgba(255, 255, 255, 0.5);
}

.content-card.content-card--itemset > .card-metas span {
  color: rgba(255, 255, 255, 0.75);
}

@media (min-width: 60em) {
  .content-card.content-card--itemset > .card-metas {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    position: absolute;
    top: 0;
    right: 2.4rem;
  }
}

.content-card.content-card--itemset h4 {
  color: #98782b;
  font-size: 2rem;
  font-weight: normal;
}

@media (min-width: 60em) {
  .content-card.content-card--itemset h4 {
    line-height: 7rem;
    flex-basis: 20%;
  }
}

.content-card.content-card--itemset > .grid > *:nth-child(1) {
  flex-basis: 6rem;
  flex-grow: 0;
}

.content-card.content-card--itemset > .grid > *:nth-child(2) {
  flex-basis: 20rem;
  flex-grow: 0;
}

.content-card.content-card--itemset > .grid > *:nth-child(3) {
  flex-basis: 12rem;
  flex-grow: 0;
}

.content-card.content-card--itemset > .grid > *:nth-child(4) {
  flex-basis: auto;
  flex-grow: 1;
}

.content-card.content-card--itemset .tiles {
  justify-content: flex-start;
}

.content-card.content-card--itemset .tile {
  margin-bottom: 1.2rem;
}

.content-card.content-card--itemset .tile--champion {
  flex-basis: 7rem;
  height: 7rem;
}

.content-card.content-card--itemset .tile--map {
  flex-basis: 14rem;
  height: 7rem;
}

.content-card.content-card--itemset .tile__name {
  display: none;
}

.content-card.content-card--itemset.content-card--primary {
  background-color: #292312;
}

.content-card.content-card--itemset.content-card--primary > h3 {
  color: #98782b;
}

.content-card.content-card--itemset.content-card--secondary {
  background-color: #000;
}

.content-card.content-card--itemset.content-card--secondary > h3 {
  color: #98782b;
}

@media (min-width: 60em) {
  .content-card.content-card--itemset {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
}

.navbar {
  display: flex;
}

.navbar > * > * {
  padding: 0 2.4rem;
  line-height: 3.6rem;
}

@media (min-width: 45em) {
  .navbar > * > * {
    line-height: 4.8rem;
  }
}

.navbar-brand {
  flex-grow: 1;
}

.navbar-brand > a {
  top: 0.6rem;
  color: #98782b;
  text-decoration: none;
  font-size: 0;
  display: block;
  width: 5.48rem;
  height: 2.0rem;
  margin-top: 0.8rem;
  margin-left: 1.2rem;
  background-size: contain;
  background-image: url("../img/logo-header.png");
}

@media (min-width: 45em) {
  .navbar-brand > a {
    width: 8.22rem;
    height: 3rem;
    margin-top: 1.1rem;
    margin-left: 2.4rem;
  }
}

.navbar-brand > a:hover {
  color: white;
}

.navbar-toggle {
  position: relative;
  z-index: 20000;
}

@media (min-width: 45em) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-toggle > button {
  position: relative;
  top: 0;
  color: inherit;
  border: none;
  background-color: transparent;
  font-weight: bold;
  transform: skewX(0);
}

/*ul*/
.navbar-nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 75vw;
  height: 100vh;
  font-size: 1.6rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  padding-left: 0;
  background-color: rgba(0, 0, 0, 0.971);
  border-left: 0.2rem solid #98782b;
  transition: all 0.2s ease-out;
  transform: translateX(75vw);
}

.navbar.visible .navbar-nav {
  transform: translateX(0);
}

.navbar-nav > li {
  flex-grow: 0;
  margin: 0;
  list-style-type: none;
}

.navbar-nav > li > a {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #aaa;
  text-transform: uppercase;
}

.navbar-nav > li > a:hover {
  color: #fff;
}

.navbar-nav > li.active > a {
  color: #fff;
}

@media (min-width: 45em) {
  .navbar-nav {
    display: block;
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    background-color: transparent;
    border-left: none;
    transform: translateX(0);
  }
  .navbar-nav > li {
    display: inline-block;
  }
  .navbar-nav > li.active {
    border-top: 0.4rem solid #98782b;
  }
}

.section {
  padding: 0 2.4rem;
  padding-bottom: 2.4rem;
}

@media (min-width: 60em) {
  .content-main .section {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
}

.section--header {
  padding: 0;
  padding-top: 32vw;
  background-size: cover;
  background-position: center;
}

.section--header.section--header--home {
  background-position: 0% 0%;
  background-image: url("/dist/img/banner_home.jpg");
}

.section--header.section--header--itemsets {
  background-image: url("/dist/img/banner_itemsets.jpg");
}

.section--header.section--header--import {
  background-image: url("/dist/img/banner_import.jpg");
}

.section--header.section--header--about {
  background-image: url("/dist/img/banner_about.jpg");
}

.section--header.section--header--donate {
  background-image: url("/dist/img/banner_donate.jpg");
}

.section--header.section--header--download {
  background-image: url("/dist/img/banner_download.jpg");
}

.section--submit {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  border-left: none;
  border-right: none;
}

@media (min-width: 60em) {
  .section--submit {
    padding-bottom: 1.2rem;
  }
}

.section-title {
  margin-left: -2.4rem;
  margin-right: -2.4rem;
  margin-bottom: 4.8rem;
  color: #080808;
  text-transform: uppercase;
  text-align: center;
  border-top: 0.2rem solid #98782b;
}

@media (min-width: 60em) {
  .content-main .section-title {
    position: absolute;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    z-index: 1000;
    width: 100%;
    top: 0;
  }
}

.section-title > span {
  position: relative;
  width: 24rem;
  display: inline-block;
  padding: 0.8rem 2.4rem;
  background-color: #98782b;
  background-image: linear-gradient(to bottom, #B88A05, #896E25);
  color: white;
  text-align: center;
  font-size: 2.4rem;
  font-weight: lighter;
  transform: skew(0);
  text-shadow: 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.5);
}

.section-title > span:after {
  content: " »";
}

.section-title > span:before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2rem;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 2rem 12rem 0 12rem;
  border-color: #896E25 transparent transparent transparent;
}

.content-aside .section-title {
  border-color: #002d3e;
}

.content-aside .section-title > span {
  background-color: #002d3e;
  color: white;
}

.content-aside .section-title > span:before {
  background-color: black;
}

.section-subtitle {
  display: inline-block;
  margin-bottom: 1.2rem;
  padding: 1.2rem 2.4rem;
  color: #98782b;
  font-size: 2rem;
  transform: skew(0);
}

.hero-content {
  padding: 4.8rem 2.4rem;
  background: linear-gradient(to bottom, transparent, black);
}

.section--header.section--header--home .hero-content {
  background: transparent;
}

.page-title,
.page-subtitle,
.page-cta {
  text-align: center;
  padding: 0 2.4rem;
}

.page-title {
  font-size: 5.2rem;
  color: white;
  text-shadow: 2 2 0 rgba(0, 0, 0, 0.75);
  font-weight: bold;
  margin: 0;
}

.page-subtitle {
  font-size: 4rem;
  font-weight: lighter;
  text-align: center;
  color: #eee;
}

.page-subtitle .page-subtitle-emphasis {
  color: #98782b;
  font-weight: bold;
}

.page-cta {
  margin-top: 4.8rem;
}

.page-cta--home {
  margin-bottom: 4.8rem;
}

.filters {
  margin-bottom: 4.8rem;
}

.form-block {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.6rem;
  margin-right: -0.6rem;
  margin-bottom: 1.2rem;
}

.form-title,
.form-item,
.form-input {
  margin-bottom: 1.2rem;
}

.form-title,
.form-input,
.form-label {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  font-size: 1.6rem;
}

.form-item--radio,
.form-item--checkbox,
.form-input {
  text-align: center;
  border: 0.2rem solid #98782b;
}

.form-item--radio.form-input,
.form-item--radio > .form-label,
.form-item--checkbox.form-input,
.form-item--checkbox > .form-label,
.form-input.form-input,
.form-input > .form-label {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  background-color: #080808;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
  transition: all 0.4s;
  cursor: pointer;
}

.form-item--radio:hover,
.form-item--radio > .form-label:hover,
.form-item--checkbox:hover,
.form-item--checkbox > .form-label:hover,
.form-input:hover,
.form-input > .form-label:hover {
  background-color: black;
}

.form-title,
.form-item {
  transform: skew(0);
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}

.form-title {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  text-align: center;
  text-transform: uppercase;
  background-color: #98782b;
  color: white;
  border: 0.2rem solid #98782b;
  background-image: linear-gradient(to bottom, #B88A05, #896E25);
  text-shadow: 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.5);
  font-size: 1.6rem;
}

.form-item {
  flex-grow: 1;
}

.form-item--text > .form-input {
  width: 100%;
  text-align: left;
  background-color: white;
  border-color: white;
  color: #757575;
  height: 4.6rem;
  font-family: 'Roboto', sans-serif;
  cursor: text;
}

.form-item--text > .form-input::placeholder {
  color: #a6a6a6;
}

.form-item--radio > .form-label,
.form-item--checkbox > .form-label {
  display: block;
}

.form-item--radio > .form-input:checked + .form-label,
.form-item--checkbox > .form-input:checked + .form-label {
  background-color: #98782b;
}

.form-input {
  margin-bottom: 0;
  flex-basis: 100%;
  background-color: #222;
  color: #aaa;
}

.form-item--checkbox > .form-input[type="checkbox"] {
  display: none;
}

.form-item--text + .form-item--checkbox {
  margin-left: -0.6rem;
  border-bottom: 0;
}

.form-btn-delete {
  position: relative;
  height: 4.4rem;
}

.form-btn-delete:before, .form-btn-delete:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 2.4rem;
  height: 0.2rem;
  background-color: #98782b;
}

.form-btn-delete:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.form-btn-delete:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.form-item--radio > .form-input[type="radio"] {
  display: none;
}

.form-item--itemsets {
  margin-left: 0;
  margin-right: 0;
}

.btn {
  display: inline-block;
  margin: 0 0.6rem;
  padding: 1.8rem 3.6rem;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transform: skew(0);
  font-size: 1.8rem;
  line-height: 1;
  font-weight: normal;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
  transition: all 0.2s;
  color: #98782b;
  background-color: #000;
  border: 1px solid #98782b;
}

.btn:hover {
  background-color: #98782b;
  color: #000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.22), 0 14px 56px rgba(0, 0, 0, 0.25);
}

.btn.btn--primary {
  color: #111;
  background-color: #98782b;
  border: 1px solid #d0ad59;
  border-bottom-color: #483915;
  border-right-color: #483915;
}

.btn.btn--primary:hover {
  background-color: #705920;
}

.btn.btn--secondary {
  background-color: #002d3e;
  border: 1px solid #0077a4;
  border-bottom-color: black;
  border-right-color: black;
}

.btn.btn--secondary:hover {
  background-color: #00080b;
}

.cta {
  margin-top: 2.4rem;
  text-align: center;
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  margin-bottom: 2.4rem;
}

.tile {
  position: relative;
  flex-basis: 8.5rem;
  flex-shrink: 1;
  align-content: flex-start;
  height: 8.5rem;
  margin: 1.2rem;
  margin-bottom: 3.6rem;
  margin-top: 0;
  padding: 0.6rem;
  transform: skew(0);
  transition: all 0.4s;
}

.tile.tile--map {
  flex-basis: 17rem;
}

.tile.tile--item {
  flex-basis: 7rem;
  height: 7rem;
}

.itemset-block__content .tile.tile--item {
  margin-bottom: 1.2rem;
}

.tile:not(.tile--passive):hover {
  cursor: pointer;
}

.content-aside .tile {
  flex-basis: 6.8rem;
  height: 6.8rem;
  margin-bottom: 0;
}

.content-aside .tile.tile--map {
  flex-basis: 13.6rem;
}

.tile:after {
  content: "";
  display: block;
  position: absolute;
  z-index: +1;
  width: 0;
  height: 0;
  border-style: solid;
}

.tile:after {
  bottom: 0;
  right: 0;
  border-width: 0 0 4.8rem 4.8rem;
  border-color: transparent transparent transparent transparent;
}

.tile.tile--selected:after {
  border-color: transparent transparent #98782b transparent;
}

.tile.tile--selected.tile--any:after {
  border-color: transparent;
}

.tile__image {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  box-shadow: 0 0 0 0.4rem #000, 0 0 0 0.6rem #98782b;
}

.tile--map .tile__image {
  padding-bottom: 46.2%;
}

.content-aside .tile--map .tile__image {
  padding-bottom: 45.0%;
}

.tile--selected .tile__image {
  border-color: #98782b;
}

.tile__image > img {
  max-width: none;
  position: absolute;
  width: 115%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) skew(0);
  transition: all 0.4s;
}

.tile:not(.tile--passive):hover .tile__image > img {
  width: 135%;
}

.tile__name,
.tile__title {
  display: inline-block;
  position: absolute;
  left: -0.6rem;
  padding: 0.6rem;
  color: rgba(255, 255, 255, 0.925);
  text-align: right;
  transition: all 0.2s;
  white-space: nowrap;
  font-weight: normal;
}

.tile__name {
  bottom: -3rem;
}

.tile--selected .tile__name {
  color: #98782b;
}

.tile__title {
  display: none;
  bottom: 2.4rem;
  padding-top: 1.2rem;
  opacity: 0;
  font-size: 75%;
}

.tile:not(.tile--passive):hover .tile__title {
  bottom: 0.6rem;
  opacity: 1;
  color: white;
  background-color: black;
}

.tile__lore {
  display: none;
}

.tile__hidden-input {
  display: none;
}

.tooltip-header {
  display: flex;
}

.tooltip-content__image {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 5.25rem;
}

.tooltip-content__image > img {
  display: block;
  width: 100%;
}

.tooltip-content__body {
  flex-grow: 1;
  margin-left: 1.2rem;
}

.item-desc__title {
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: bold;
  color: white;
}

.item-desc__gold {
  font-size: 1.6rem;
  font-weight: bold;
  color: #98782b;
  margin-bottom: 1.2rem;
}

.item-desc__description .item-desc__stats,
.item-desc__description .item-desc__property {
  color: #98782b;
}

.gold-icon {
  position: relative;
  top: 0.125rem;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-image: url("../img/gold.png");
}

.list-icon {
  position: relative;
  top: 1.25rem;
  display: inline-block;
  width: 4rem;
  height: 3.7rem;
  background-image: url("../img/list-icon.png");
}

.cloud-icon {
  position: relative;
  top: 1.25rem;
  display: inline-block;
  width: 3.7rem;
  height: 3.1rem;
  background-image: url("../img/clowd-icon.png");
}

@media (min-width: 60em) {
  .tiles--itemsets-maps {
    justify-content: flex-start;
  }
}

@media (min-width: 60em) {
  .form-itemsets .grid--itemsets-maps > article {
    flex-basis: 54%;
  }
  .form-itemsets .grid--itemsets-maps > article:first-child {
    flex-basis: 46%;
  }
}

@media (min-width: 80em) {
  .form-itemsets .grid--itemsets-maps > article {
    flex-basis: 62%;
  }
  .form-itemsets .grid--itemsets-maps > article:first-child {
    flex-basis: 38%;
  }
}

.itemset-block {
  margin-bottom: 2.4rem;
  transform: skew(0);
}

.itemset-block__name {
  margin-bottom: 0;
  display: flex;
  flex-wrap: nowrap;
}

.itemset-block__name > .form-item {
  margin-bottom: 0;
  transform: skew(0);
  flex-basis: 50%;
}

.itemset-block__name > .form-item.form-item--text {
  flex-grow: 1;
}

.itemset-block__name > .form-item.form-item--checkbox {
  flex-grow: 0;
  flex-basis: auto;
}

.itemset-block--add .itemset-block__name {
  display: none;
}

.itemset-block__content {
  padding-top: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  min-height: 9.8rem;
  border: 0.2rem solid #98782b;
}

.itemset-block__content > .tile--item {
  transform: skew(0);
}

.itemset-block__content > .tile--item .tile__content {
  display: none;
}

.itemset-block--add .itemset-block__content {
  padding: 1.2rem;
  justify-content: center;
  height: 100px;
  line-height: 70px;
  border: 0.2rem dashed;
  background-color: transparent;
  cursor: pointer;
  border-color: #98782b;
  font-style: italic;
}

.itemset-block--add .itemset-block__content span {
  color: #98782b;
}

.file-upload {
  text-align: center;
}

.file-upload__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-upload__input:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.file-upload__label {
  display: block;
  max-width: 65rem;
  margin: 2.4rem auto;
  padding: 4.8rem;
  padding-top: 3.6rem;
  border: 0.2rem dashed;
  border-color: #98782b;
  color: #fff;
  transform: skewX(0);
  cursor: pointer;
  font-style: italic;
}

.file-upload__label .color-primary,
.file-upload__label #fileupload-filename {
  color: #98782b;
}

.itemsets-champions {
  margin-top: 4.8rem;
}

.form-itemsets {
  text-align: center;
}

.form-itemsets > button {
  margin-top: 1.2rem;
}

.listAlpha {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.listAlpha > li {
  list-style-type: none;
}

.listAlpha > li.disabled {
  padding: 0.6rem 1.2rem;
  margin: 0.3rem;
}

.listAlpha > li > a {
  display: block;
  padding: 0.6rem 1.2rem;
  margin: 0.3rem;
  background-color: #000;
  text-decoration: none;
  border: 0.1rem solid #98782b;
}

.listAlpha > li > a:hover {
  background-color: #111;
}

.listAlpha > li.alphaOn > a {
  background-color: #292312;
}

.form--paypal input[type="image"] {
  display: none;
}

/* This is the core CSS of Tooltipster */
/* GENERAL STRUCTURE RULES (do not edit this section) */
.tooltipster-base {
  /* this ensures that a constrained height set by functionPosition,
	if greater that the natural height of the tooltip, will be enforced
	in browsers that support display:flex */
  display: flex;
  pointer-events: none;
  /* this may be overriden in JS for fixed position origins */
  position: absolute;
}

.tooltipster-box {
  /* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
	and flex-basis auto for IE11- (at least) */
  flex: 1 1 auto;
}

.tooltipster-content {
  /* prevents an overflow if the user adds padding to the div */
  box-sizing: border-box;
  /* these make sure we'll be able to detect any overflow */
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
  padding: 2.4rem 1.2rem;
  background-color: black;
  border: 0.2rem solid #98782b;
  transform: skewX(0);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
}

@media (min-width: 60em) {
  .tooltipster-content {
    width: 33vw;
  }
}

.tooltipster-ruler {
  /* these let us test the size of the tooltip without overflowing the window */
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
}

/* ANIMATIONS */
/* Open/close animations */
/* fade */
.tooltipster-fade {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -ms-transition-property: opacity;
  transition-property: opacity;
}

.tooltipster-fade.tooltipster-show {
  opacity: 1;
}

/* grow */
.tooltipster-grow {
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-backface-visibility: hidden;
}

.tooltipster-grow.tooltipster-show {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

/* swing */
.tooltipster-swing {
  opacity: 0;
  -webkit-transform: rotateZ(4deg);
  -moz-transform: rotateZ(4deg);
  -o-transform: rotateZ(4deg);
  -ms-transform: rotateZ(4deg);
  transform: rotateZ(4deg);
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
}

.tooltipster-swing.tooltipster-show {
  opacity: 1;
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 1);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -moz-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -ms-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -o-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
}

/* fall */
.tooltipster-fall {
  -webkit-transition-property: top;
  -moz-transition-property: top;
  -o-transition-property: top;
  -ms-transition-property: top;
  transition-property: top;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-fall.tooltipster-initial {
  top: 0 !important;
}

.tooltipster-fall.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  top: 0 !important;
  opacity: 0;
}

/* slide */
.tooltipster-slide {
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  -ms-transition-property: left;
  transition-property: left;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-slide.tooltipster-initial {
  left: -40px !important;
}

.tooltipster-slide.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  left: 0 !important;
  opacity: 0;
}

/* Update animations */
/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */
/* fade */
@keyframes tooltipster-fading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tooltipster-update-fade {
  animation: tooltipster-fading 400ms;
}

/* rotate */
@keyframes tooltipster-rotating {
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0);
  }
}

.tooltipster-update-rotate {
  animation: tooltipster-rotating 600ms;
}

/* scale */
@keyframes tooltipster-scaling {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.tooltipster-update-scale {
  animation: tooltipster-scaling 600ms;
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}

/* perfect-scrollbar v0.6.16 */
.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

@supports (-ms-overflow-style: none) {
  .ps-container {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps-container {
    overflow: auto !important;
  }
}

.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
  display: block;
  background-color: transparent;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
  height: 11px;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
  width: 11px;
}

.ps-container > .ps-scrollbar-x-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  opacity: 0;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  -o-transition: background-color .2s linear, opacity .2s linear;
  -moz-transition: background-color .2s linear, opacity .2s linear;
  transition: background-color .2s linear, opacity .2s linear;
  bottom: 0px;
  /* there must be 'bottom' for ps-scrollbar-x-rail */
  height: 15px;
}

.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  bottom: 2px;
  /* there must be 'bottom' for ps-scrollbar-x */
  height: 6px;
}

.ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x, .ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x {
  height: 11px;
}

.ps-container > .ps-scrollbar-y-rail {
  display: none;
  position: absolute;
  /* please don't change 'position' */
  opacity: 0;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  -o-transition: background-color .2s linear, opacity .2s linear;
  -moz-transition: background-color .2s linear, opacity .2s linear;
  transition: background-color .2s linear, opacity .2s linear;
  right: 0;
  /* there must be 'right' for ps-scrollbar-y-rail */
  width: 15px;
}

.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  position: absolute;
  /* please don't change 'position' */
  background-color: #aaa;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
  -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
  right: 2px;
  /* there must be 'right' for ps-scrollbar-y */
  width: 6px;
}

.ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y, .ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
  width: 11px;
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
  height: 11px;
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
  width: 11px;
}

.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
  opacity: 0.6;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover {
  background-color: #eee;
  opacity: 0.9;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color: #999;
}
