////////////////////////////////////////////////////////////////////////////////
// GRID

.row {
  display: flex;
  flex-wrap: wrap;

  @include bp('md') {
    flex-wrap: nowrap;
  }

  > * {
    flex-basis: 100%;
		flex-grow: 1;

    @include bp('md') {
      flex-basis: auto;
    }
  }
}

.col {}

@include bp('md') {
  .col--maps {          flex-basis: 27%; }
  .col--champions {     flex-basis: 73%; }

	.col--items-filter {  flex-basis: 27%; }
  .col--items {         flex-basis: 36.5%; }
  .col--itemset {       flex-basis: 36.5%; }
}

////////////////////////////////////////////////////////////////////////////////
// MINI-GRID

.grid {
	display: flex;
	flex-wrap: wrap;
	margin: -$gutter-half;

	@include bp('md') {
		flex-wrap: nowrap;
	}

	> * {
		flex-grow: 1;
		flex-basis: 100%;
		margin: $gutter-half;
		// background-color: rgba(255, 0, 0, 0.125);
	}

	@include bp('md') {
		flex-basis: auto;
	}
}
