// Google fonts
@import url('https://fonts.googleapis.com/css?family=Averia+Serif+Libre:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

// ROOT

html { font-size: 62.5%; }

body {
  font-size: $fontsize-base;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
}

.btn,
.hero-content,
h1, h2, h3, h4,
.form-title,
.section-title,
.section-subtitle,
.header,
.item-desc__title,
.item-desc__gold,
.no-result {
  font-family: 'Averia Serif Libre', cursive;
}

// ELEMENTS

h1, h2, h3,
p
{
  margin-bottom: $gutter;
}

h1 {
  font-size: $fontsize-biggest * 2;
  font-weight: bold;
}

h2 {
  font-size: $fontsize-biggest;
  font-weight: bold;
  text-transform: uppercase;
}

h3 {
  font-size: $fontsize-biggest;
  font-weight: bold;
  margin-bottom: $gutter-half;
}

p {
  margin-bottom: $gutter-half;
}

ul {
  padding-left: $gutter-half * 1.5; // ow!
}

li {
  list-style-type: circle;
  margin-bottom: $gutter-half;
}

blockquote {
  margin-bottom: $gutter;
  font-style: italic;
}

hr {
  margin-bottom: $gutter-half;
}

a {
  color: $color-1;
  font-weight: bold;
}
