img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

h2 { color: $color-1; }
h3 { color: $color-lisb-dark; }

p {
}

blockquote {
  padding-left: $gutter-half;
  border-left: 0.4rem solid $color-2;
}
